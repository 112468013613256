export const environment = {
  production: true,
  env: 'prod',
  use_hash_for_routing: false,
  version: '6.29.12',
  auth_client_id: '2_647eboqlg9ogwwccog0cc4w08sw44oggoogcsowg4cgc4ockoo',
  auth_client_secret: '2gkmgqea0pgko844oc80c4co0440wgo808gg0gcgwkooo8wcc0',
  auth_fb_grant_type: 'https://dev.ecni.fr/facebook',
  auth_google_grant_type: 'https://dev.ecni.fr/google',
  adwordsKey: 'AW-980716637/E5usCO7xo50ZEN2Y0tMD',

  site_id: 'prepmir',
  site_name: 'prepMIR',
  defaultLanguage: 'es',
  locale: 'es-ES',
  localeApple: 'es_ES',
  api_base_url: 'https://api.prepmir.es/api',
  maintenance_endpoint:
    'https://hueg7rfcof.execute-api.eu-west-1.amazonaws.com/release/prepmir-prod-maintenance',
  stripe_api_key:
    'pk_live_51Kcr2lFvEMzZRdPmh4HnJnbiQuY65TdX6n6Q33RS5cJshiTmwHfdez4ohF92jQHtpiEVRPVxxsgqgYBvnkjicPMO00ZCIdolFO',
  google_web_id: '765510329267-tcuc8p01lpfn5u71fceg22288n2vdif2.apps.googleusercontent.com',
  pusher_id: '2614bafe0c6486fcb67c',
  fb_id: '215852823176338',
  google_play_id: 'es.prepmir.app',
  appstore_id: 'id1527248161',
  appstore_service_id: 'es.prepmir.service',
  apple_redirect_uri: 'https://www.prepmir.es',
  pushChannel: 'prepmir_push_channel',
  pushName: 'prepMIR.es',
  intercomAppId: 'taxbq1ei',
  intercomHelpdeskUrl: 'https://intercom.help/prepmir',
  facebook_url: 'https://www.facebook.com/prepmir',
  instagram_url: 'https://www.instagram.com/prepmir/',
  tiktok_url: 'https://www.tiktok.com/@prepmir',
  telegram_url: 'https://t.me/+05uxmdQ410M2YTdk',
  admin_url: 'https://admin.prepmir.es',
  domain: 'prepmir.es',
};
